<template>
  <div class="notfound-body-style" :style="{'background-color':$store.state.corpInfo.darkTheme?'black':'#E2E6EF'}">
    <NotFoundBody />
  </div>  
</template>

<script>
import NotFoundBody from '../components/404-NotFound/404-body.vue';

export default {
  components: {
    NotFoundBody
  },
  mounted() {
    this.$root.loading = false;
  }
}
</script>

<style scoped>
  .notfound-body-style {
    height: 100%;
  }
</style>
